<template>
  <div :class="[$style.component, { [$style['component--error']]: v$.region.geometryWkt.$error }]">
    <div
      :class="$style.component__map"
      ref="mapContainer"
    ></div>
  </div>
</template>
<script>
  import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue';
  import { useStore } from 'vuex';
  import L from 'leaflet';
  import 'leaflet-draw';
  import 'leaflet.gridlayer.googlemutant';
  import { parse, stringify } from 'wellknown';
  import { EditFeatureGroup, MapOptions, DrawOptions, GoogleMutantOptions } from '@/common/constants/map';
  import RegionsStore from '@/components/regions/regions.store';
  import RegionStore from '@/components/regions/region/region.store';

  export default defineComponent({
    emits: ['update:draw-edit'],
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props, { emit }) {
      const { state, commit } = useStore();
      const region = computed(() => state[RegionsStore.name][RegionStore.name].region);
      const mapContainer = ref(null);
      let map = null;
      const drawDrawStart = () => {
        EditFeatureGroup.clearLayers();
        commit(`${RegionsStore.name}/${RegionStore.name}/setRegion`, {
          ...region.value,
          geometryWkt: null,
        });
      };
      const drawCreated = ({ layer }) => {
        EditFeatureGroup.addLayer(layer);
        commit(`${RegionsStore.name}/${RegionStore.name}/setRegion`, {
          ...region.value,
          geometryWkt: stringify(layer.toGeoJSON()),
        });
      };
      const drawEditStart = () => {
        emit('update:draw-edit', true);
      };
      const drawEditStop = () => {
        emit('update:draw-edit', false);
      };
      const drawEdited = ({ layers }) => {
        const [layer] = layers.getLayers();
        commit(`${RegionsStore.name}/${RegionStore.name}/setRegion`, {
          ...region.value,
          geometryWkt: stringify(layer.toGeoJSON()),
        });
      };
      onMounted(() => {
        map = L.map(mapContainer.value).setView(MapOptions.center, MapOptions.zoom);
        L.gridLayer.googleMutant(GoogleMutantOptions).addTo(map);
        EditFeatureGroup.addTo(map);
        map.addControl(new L.Control.Draw(DrawOptions));
        map.on(L.Draw.Event.DRAWSTART, drawDrawStart);
        map.on(L.Draw.Event.CREATED, drawCreated);
        map.on(L.Draw.Event.EDITSTART, drawEditStart);
        map.on(L.Draw.Event.EDITSTOP, drawEditStop);
        map.on(L.Draw.Event.EDITED, drawEdited);
        if (region.value.geometryWkt) {
          const { coordinates } = parse(region.value.geometryWkt);
          const latLng = coordinates
            .flat(MapOptions.flatPolygon)
            .map(([lng, lat]) => L.latLng({ lng, lat }));
          const polygon = L.polygon(latLng, { ...MapOptions.polygon });
          EditFeatureGroup.addLayer(polygon);
          map.fitBounds(EditFeatureGroup.getBounds());
        }
      });
      onUnmounted(() => {
        if (map) {
          EditFeatureGroup.clearLayers();
          map.remove();
        }
      });
      return {
        mapContainer,
        region,
        v$: ref(props.validations),
      };
    },
  });
</script>
<style lang="scss" module>
  @import "~@/styles/quasar.variables";

  .component {
    border: 2px solid transparent;
    border-radius: 4px;
    overflow: hidden;

    &--error {
      border: 2px solid $negative;
    }
    &__map {
      height: 550px;
    }
    :global .leaflet-draw-actions {
      a {
        color: white;
      }
    }
  }
</style>
