<template>
  <em-table
    v-model:pagination="searchOutput.pagination"
    :fetch="getRegions"
    :columns="SearchColumns"
    :rows="searchOutput.content"
    row-key="name"
  >
    <template #scope="{ item }">
      <em-country-label :country-names="item.countryNames"></em-country-label>
    </template>
    <template #actions="{ item }">
      <q-btn
        v-if="hasFeature(Feature.regionsWrite)"
        dense
        flat
        round
        icon="create"
        @click="onEditClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.MODIFY') }}</span>
        </q-tooltip>
      </q-btn>
      <q-btn
        v-if="hasFeature(Feature.regionsDelete)"
        dense
        flat
        round
        icon="delete"
        @click="onDeleteClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.DELETE') }}</span>
        </q-tooltip>
      </q-btn>
    </template>
  </em-table>
</template>
<script>
  import { defineComponent, computed, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import { hasFeature } from '@/store/helpers/access.helper';
  import { Feature } from '@/common/constants/security';
  import { SearchColumns } from '@/components/regions/search/search.constant';
  import RegionsStore from '@/components/regions/regions.store';
  import SearchStore from '@/components/regions/search/search.store';
  import RegionStore from '@/components/regions/region/region.store';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const searchInput = computed(() => state[RegionsStore.name][SearchStore.name].searchInput);
      const searchOutput = computed(() => state[RegionsStore.name][SearchStore.name].searchOutput);
      const $confirm = inject('$confirm');

      function getRegions(payload) {
        commit(`${RegionsStore.name}/${SearchStore.name}/setSearchInput`, {
          ...searchInput.value,
          ...payload,
        });
        dispatch(`${RegionsStore.name}/${SearchStore.name}/getRegions`, searchInput.value.searchObj);
      }
      return {
        hasFeature,
        Feature,
        SearchColumns,
        searchInput,
        searchOutput,
        getRegions,
        onEditClick(payload) {
          commit(`${RegionsStore.name}/${RegionStore.name}/setRegion`, payload);
          router.push({ name: 'regions.region', params: { id: payload.id } });
        },
        async onDeleteClick({ id }) {
          const result = await $confirm({
            content: t('MBO.ARE_YOU_SURE_DELETE_ITEM'),
          });
          if (result) {
            try {
              await dispatch(`${RegionsStore.name}/${SearchStore.name}/deleteRegionById`, id);
              await getRegions();
              $q.notify(t('MBO.REGION_WAS_DELETED'));
            } catch (error) {
              $q.notify(t('MBO.SOMETHING_WENT_WRONG'));
              throw error;
            }
          }
        },
      };
    },
  });
</script>
