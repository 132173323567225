<template>
  <div class="q-gutter-lg">
    <div>
      <em-input-languages :errors="[v$.region.name]">
        <template #input="{ code }">
          <div class="q-gutter-md">
            <q-input
              v-model="region.name[code]"
              :error="v$.region.name[code] && v$.region.name[code].$error"
              :label="$t('MBO.NAME')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
        </template>
      </em-input-languages>
    </div>
    <div>
      <div class="row q-col-gutter-sm">
        <div class="col-12 col-sm-6">
          <em-country-select
            v-model="region.countries"
            :error="v$.region.countries.$error"
            :label="$t('MBO.COUNTRIES')"
            multiple
          ></em-country-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import RegionsStore from '@/components/regions/regions.store';
  import RegionStore from '@/components/regions/region/region.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state } = useStore();
      const region = computed(() => state[RegionsStore.name][RegionStore.name].region);
      return {
        v$: ref(props.validations),
        region,
      };
    },
  });
</script>
