<template>
  <router-view></router-view>
</template>
<script>
  import { defineComponent, onUnmounted } from 'vue';
  import Store from '@/store';
  import RegionsStore from '@/components/regions/regions.store';

  export default defineComponent({
    beforeRouteEnter(to, from, next) {
      Store.registerModule(RegionsStore.name, RegionsStore.module);
      next();
    },
    setup() {
      onUnmounted(() => {
        Store.unregisterModule(RegionsStore.name);
      });
    },
  });
</script>
