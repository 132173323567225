<template>
  <header class="text-h6 q-pb-md text-center">
    <span v-if="region.id">{{ $t('MBO.EDIT_REGION') }}</span>
    <span v-else>{{ $t('MBO.ADD_NEW_REGION') }}</span>
  </header>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import RegionsStore from '@/components/regions/regions.store';
  import RegionStore from '@/components/regions/region/region.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const region = computed(() => state[RegionsStore.name][RegionStore.name].region);
      return {
        region,
      };
    },
  });
</script>
