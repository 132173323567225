<template>
  <div :class="$style.component">
    <div class="q-pa-md overflow-auto">
      <header-component></header-component>
      <form-component :validations="v$"></form-component>
      <map-component
        :validations="v$"
        @update:draw-edit="onDrawEdit($event)"
      ></map-component>
    </div>
    <q-space></q-space>
    <footer-component
      :validations="v$"
      :is-editing="isEditing"
    ></footer-component>
  </div>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import i18n from '@/i18n';
  import HeaderComponent from '@/components/regions/region/components/header.component.vue';
  import FormComponent from '@/components/regions/region/components/form.component.vue';
  import MapComponent from '@/components/regions/region/components/map.component.vue';
  import FooterComponent from '@/components/regions/region/components/footer.component.vue';
  import Store from '@/store';
  import DictionariesStore from '@/store/modules/dictionaries.store';
  import RegionsStore from '@/components/regions/regions.store';
  import RegionStore from '@/components/regions/region/region.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const regionId = to.params.id;
        if (regionId) {
          await Store.dispatch(`${RegionsStore.name}/${RegionStore.name}/getRegionById`, regionId);
        } else {
          Store.commit(`${RegionsStore.name}/${RegionStore.name}/setRegion`);
        }
        next();
      } catch (error) {
        next({ name: 'regions.search' });
        throw error;
      }
    },
    setup() {
      const { state } = useStore();
      const v$ = useVuelidate();
      const isEditing = ref(false);
      const region = computed(() => state[RegionsStore.name][RegionStore.name].region);
      const countries = computed(() => state[`${DictionariesStore.name}`].countries);
      return {
        v$,
        isEditing,
        region,
        countries,
        onDrawEdit(payload) {
          isEditing.value = payload;
        },
      };
    },
    validations() {
      return {
        region: {
          name: { [i18n.global.locale]: { required } },
          countries: { required },
          geometryWkt: { required },
        },
      };
    },
    components: {
      HeaderComponent,
      FormComponent,
      MapComponent,
      FooterComponent,
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/common/item-component';

  .component {
    @include item-component();
  }
</style>
