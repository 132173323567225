<template>
  <footer>
    <q-separator></q-separator>
    <div class="q-px-md q-py-xs flex">
      <q-space></q-space>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.BACK')"
        class="q-ma-xs"
        @click="onBackClick"
      ></q-btn>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :disable="isEditing"
        :label="$t('MBO.SAVE')"
        class="q-ma-xs"
        color="primary"
        @click="onSaveClick()"
      ></q-btn>
    </div>
  </footer>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import RegionsStore from '@/components/regions/regions.store';
  import RegionStore from '@/components/regions/region/region.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { state, dispatch } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const region = computed(() => state[RegionsStore.name][RegionStore.name].region);
      return {
        region,
        onBackClick() {
          router.push({ name: 'regions.search' });
        },
        async onSaveClick() {
          props.validations.region.$touch();
          if (props.validations.region.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
          } else {
            await dispatch(`${RegionsStore.name}/${RegionStore.name}/saveRegion`, region.value);
            $q.notify(t('MBO.REGION_WAS_SAVED'));
            await router.push({ name: 'regions.search' });
          }
        },
      };
    },
  });
</script>
